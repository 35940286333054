<template>
  <div>
    <b-card>
      <form-render
        :form.sync="formCarriers"
        :fields="fieldsCarriers"
        :key="keyFormRenderCarriers"
        containerButtonsClass="col-sm-12 col-md-8 col-lg-3 container-button mt-2"
      >
      </form-render>
      <div>
        <table-render
          v-if="!loading.first"
          :key="keyTableRender"
          :schema="schema"
          :loading="loading.allCarriers && (formCarriers.carriersFilter && formCarriers.carriersFilter.id === 2)"
          :rows="rows"
          id="table-carriers-client"
        >
          <template #name="scope">
            <img-loader :src="`https://storage.googleapis.com/enviame-misc/carriers/logos/color/${scope.rowdata.carrier_code.toLowerCase()}.png`" :refId="scope.rowdata.name" :alt="`Logo ${scope.rowdata.name}`" classImg="logo-adapter"/>
          </template>
        </table-render>
        <pagination
          v-show="formCarriers.carriersFilter && formCarriers.carriersFilter.id === 2"
          :pagination="pagination"
          :showSize="true"
        />

        <div class="table-render-skeleton" v-show="loading.first">
          <b-skeleton-table   
            :rows="pagination.limit || 10"    
            :columns="schema.length || 10"
            :table-props="{ }"/>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'carriers-client',
  data() {
    return {
      schema: [],
      rows: [],
      fields: [],
      keyTableRender: 0,
      countryOptions: [
        { id: 1, text: 'Chile' },
        { id: 2, text: 'Mexico' },
        { id: 3, text: 'Colombia' },
        { id: 4, text: 'Peru' },
        { id: 5, text: 'Argentina' },
        { id: 6, text: 'Ecuador'}
      ],
      loading: {
        allCarriers: true,
        first: true
      },
      optionsFilterCarriersType: [
        { id: 1, text: this.$t('Mis couriers') },
        { id: 2, text: this.$t('Todos'), disabled: true }
      ],
      formCarriers: {},
      fieldsCarriers: [],
      keyFormRenderCarriers: 0,
      couriersCompany: [],
      allCouriersByCountry: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 10
      },
      country_id: null,
      roleKey: {
        seller: 'shipper',
        ecommerce: 'organization',
        marketplace: 'organization'
      }
    }
  },
  mounted() {
    if (this.mySession.id) this.setInitialData()
  },
  computed: {
    ...mapGetters({
      allCarriers: 'getAllCarriers',
      courierCompanies: 'getCouriers',
      shipperInfo: 'getShipperInfo',
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    'pagination.page' () {
      this.getAllCouriers()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.getAllCouriers()
    },
    courierCompanies() {
      this.couriersCompany = this.courierCompanies.map((carrier) => {
        return {
          ...carrier,
          carrier_code: carrier.code,
          country_name: carrier.countries[0].name,
          service_name : carrier.services.map((service) => service.name).join(' - '),
          service_code : carrier.services.map((service) => service.code).join(' - ')
        }
      })
      this.rows = this.couriersCompany
      this.loading.first = false
    },
    shipperInfo() { //Obtenemos el dato del shipper para filtrar todos los disponibles por el pais del mismo
      this.country_id = this.shipperInfo.country_id
      this.getAllCouriers()
    },
    allCarriers() {
      this.allCouriersByCountry = this.allCarriers.data.map((carrier) => {
        return {
          ...carrier,
          carrier_code: carrier.code,
          country_name: carrier.country.name,
          service_name : carrier.services.map((service) => service.name).join(' - '),
          service_code : carrier.services.map((service) => service.code).join(' - ')
        }
      })
      if (this.formCarriers.carriersFilter && this.formCarriers.carriersFilter.id === 2) this.rows = this.allCouriersByCountry
      this.pagination.page = this.allCarriers.pagination.current_page
      this.pagination.total = this.allCarriers.pagination.total
      this['pagination.limit'] = this.allCarriers.pagination.total_pages
      this.loading.allCarriers = false
      this.optionsFilterCarriersType[1].disabled = false
    }
  },
  methods: {
    setInitialData() {
      this.schema = [
        { label: 'País', sortable: true, key: 'country_name' },
        { label: 'Courier', sortable: true, key: 'name', useSlot: true },
        { label: 'Codigo de Courier', sortable: true, key: 'carrier_code' },
        { label: 'Nombre del servicio', key: 'service_name' },
        { label: 'Código del servicio', key: 'service_code' }
      ]
      this.fields = [
        { fieldType: 'FieldSelect', name: 'country', label: 'País', containerClass: 'col-sm-12 container-info col-md-2', options: this.countryOptions }
      ]
      this.fieldsCarriers = [
        {
          fieldType: 'FieldRadio',
          name: 'carriersFilter',
          containerClass: 'col-sm-12 col-md-6 container-info',
          validation: 'required',
          align: 'h', change: this.changeFieldsCarriers,
          options: this.optionsFilterCarriersType
        }
      ]
      this.formCarriers.carriersFilter = this.optionsFilterCarriersType[0]
      this.carriersByOrganization(this.formCarriers.carriersFilter)
    },
    carriersByOrganization() {
      const role = this.mySession.role
      const queryParams = {
        shipper_id : ['admin'].includes(role) ? this.$route.params.id : this.mySession[this.roleKey[role]].id
      }
      this.$store.dispatch('fetchService', {
        name: 'getCouriers',
        queryParams
      })
    },
    getAllCouriers() {
      this.loading.allCarriers = true
      const queryParams = {
        page: this.pagination.page,
        limit: this.pagination.limit
      }
      if (this.country_id) queryParams.country_id = this.country_id
      this.$store.dispatch('fetchService', {
        name: 'getAllCarriers',
        queryParams,
        onError: () => this.loading.allCarriers = false
      })
    },
    changeFieldsCarriers(name, value) {
      if (value.id === 1) {
        this.rows = this.couriersCompany
      } else {
        this.rows = this.allCouriersByCountry
      }
    }
  }
}
</script>
